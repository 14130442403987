import { Setter } from "../../services/ComService";
import { DisplayDateTimeNumeric } from "../../services/DateTime";
import { parseStringFromServer } from "../../services/TextConverter";
import { IProp } from "./PropDTO";

export type Log = {
  name: string,
  displayValue2: string,
  displayValue1: string,
  separator: string,
  origKey: string,
}

export const LogKey2Name = [
  ['created', 'erstellt am'],
  ['createUserId', 'erstellt am'],
  ['changed', 'geändert'],
  ['delete_check', 'gelöscht'],
  ['rename', 'umbenannt'],
  ['renameUserId', 'umbenannt am'],
  ['removedCustomerUserId', 'entfernt am'],
  ['setUp', 'Vorlage gewählt am'],
  ['setUpName', 'Neue Vorlage'],
  ['bakedByUserId', 'gebacken am'],
  ['bakedDate', 'gebacken'],
  ['addedCustomerUserId', 'Kunde hinzugefügt am'],
  ['setStart', 'Start gesetzt'],
  ['setStartUserId', 'Start gesetzt am'],
  ['setEnd', 'Ende gesetzt'],
  ['setEndUserId', 'Ende gesetzt am'],
  ['decline', 'Abgesagt am'],
  ['addPerson', 'Person hinzugefügt am'],
  ['removePerson', 'Person entfernt am'],
  ['addCustomerToPerson', 'Kunde hinzugeügt am'],
  ['removeCustomerFromPerson', 'Kunde entfernt am'],
  ['changeStartTime', 'Startzeit geändert am', 'auf'],
  ['changeEndTime', 'Startzeit geändert am', 'auf'],
]

export async function GetLogs(logs: IProp[]) {
  let out: Log[] = [];

  for (let i = 0, m = logs.length; i < m; i++) {
    let log = logs[i]
    let displayValue2 = log.value1 ? parseStringFromServer(log.value1) : ''
    let separator = '';
    if (!isNaN(Number(log.value1)) && Number(log.value1) > 0) {
      // Do something if displayValue2 is parsable as a number
      if (log.key2.search(/(decline|addPerson|removePerson)/) > -1) {
        // let person = new ItemPersonDTO({id: Number(log.value1)});
        // await person.getFromServer();
        // displayValue2 = ' ' + person.getDisplayName();
        const person = await Setter('user/get', {id: Number(log.value1)})
        console.log('PERSON FOR LOG', person)
        displayValue2 = person.lastName + ' (' + log.value1 + ')'
      } else if (log.key2.search(/(addCustomerToPerson|removeCustomerFromPerson)/) > -1) {
        // let customer = new ItemCustomerDTO({id: Number(log.value1)});
        // await customer.getFromServer();
        // displayValue2 = ' ' + customer.name;
        // displayValue2 = '' + log.value1
        const customer = await Setter('spt/customer/get', {id: Number(log.value1)})
        console.log('LogVIew customer', customer)
        displayValue2 = customer.item.name + ' (' + log.value1 + ')'
      } else {
        // let user = new UserDTO({id: Number(log.value1)});
        // await user.getFromServer();
        // displayValue2 = 'von ' + user.getName();
        displayValue2 = '' + log.value1
      }
    }
    let displayValue1 = '';
    separator = LogKey2Name.find(l => l[0] === log.key2)?.[2] || separator
    if (log.key2 === 'rename') {
      displayValue1 = log.value1
      separator = '→'
      displayValue2 = log.value2
    } else if (log.key2 === 'setUpName') {
      displayValue1 = `${log.value1}`
      displayValue2 = `(id ${log.value2})`
    } else if (log.key2 === 'setStart') {
      separator = '→'
      displayValue1 = (log.value2 ? DisplayDateTimeNumeric(new Date(log.value2)) : '-')
      displayValue2 = (log.value1 ? DisplayDateTimeNumeric(new Date(log.value1)) : '-')
    } else {
      displayValue1 = DisplayDateTimeNumeric(new Date(log.value2))
    }
    console.log('LOGVIEW', displayValue1, separator, displayValue2, log)
    out.push({
      name: LogKey2Name.find(l => l[0] === log.key2)?.[1] || '?',
      displayValue1: displayValue1,
      displayValue2: displayValue2,
      separator: separator || '',
      origKey: log.key2
    })
  }
  console.log('GenericDTO getLogs', out, logs)
  return out;
}
