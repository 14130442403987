import { useEffect, useRef, useState } from "react";
import InputB from "../../../components/InputB/InputB";
import ItemCustomerDTO from "../../DTO/ItemCustomerDTO";
import { Plus } from "react-feather";
import ItemsDTO from "../../DTO/ItemsDTO";
import { Status } from "../day/dayView";
import Spinner from "../../../components/Spinner/Spinner";
import Customer from "./Customer";
import { MItem } from "../Menu";
import PlaningToolRoot from "../PlaningToolRoot";
import { InfinitScroll } from "../../../services/InfinitScroll";
import { OptionArray } from "../../../services/OptionArrayParser";
import { CustomersDTO } from "../../DTO/CustomersDTO";

export default function CustomerList(props: {
  selected?: number,
  onSelect?: (id: number, customer: ItemCustomerDTO) => void,
  onTrash?: (id: number) => void,
  onRemove?: (id: number) => void,
  className?: string,
  personId?: number,
  standalone?: boolean,
  noSearch?: boolean,
  rerenderTrigger?: number,
  headline?: string,
  options?: OptionArray,
}) {
  const [Needle, setNeedle] = useState('')
  const [status, setStatus] = useState(Status.loading)
  const [Items] = useState(new CustomersDTO({
    getUrl: 'spt/customer/list',
    trashUrl: 'spt/customer/delete',
    personId: props.personId || -1,
  }))
  const [infinitScroll] = useState(new InfinitScroll({
    ref: useRef(null),
    cb: async () => {
      // What to do, if we are at the end of the list?
      const newPage = infinitScroll.incrementAndLock()
      console.log('we arrived at the end of the list! new page', newPage)
      if (newPage === -1) { return }
      setStatus(Status.loadMore)
      
      const newElementCount = await Items.getFromServer(infinitScroll.getPage())
      console.log('we arrived newElementCount', newElementCount)
      if (newElementCount) {
        infinitScroll.unlock()
      }
      setStatus(Status.standard)
      // Now load new content - then set Status to "standard" and unlock
      // If nothing is loaded - lock permanently
    }
  }))
  const className = `STCustomerList flex flex-column ${props.className || ''}`
  useEffect(() => {
    setStatus(Status.loading)
    Items.getFromServer().then(() => {
      setStatus(Status.standard)
    })
  }, [props.rerenderTrigger])
  return <PlaningToolRoot standalone={props.standalone} menuItem={MItem.customers} mainContentClass={className}>
    {
      (props.standalone || props.headline) ?
      <div className='contentHeader'>
        {
          props.headline ? <h2>{props.headline}</h2> : <h1>Kunden</h1>
        }
        { !props.noSearch &&
          <InputB
            className='w3-border'
            returnVal={async (val) => {
              setStatus(Status.loading)
              await Items.setNeedle(val)
              setNeedle(val)
              setStatus(Status.standard)
            }}
            placeholder='Tippen um zu suchen oder neu anzulegen ...'
          />
        }
        {
          Needle !== '' &&
          <button
            className='positiveButton'
            onClick={async () => {
              const item = new ItemCustomerDTO({})
              await item.setName(Needle)
              if (props.onSelect) {
                props.onSelect(item.id, item)
              } else {
                setStatus(Status.loading)
                await Items.getFromServer()
                setStatus(Status.standard)
              }
            }}
          ><Plus /><span className='name'>Diesen Kunden anlegen</span></button>
        }
      </div> : null
    }
    <div
      ref={infinitScroll.getRef()}
      className={`CustomerList ${props.standalone ? 'scrollContent' : ''}`}
    >
      {
        status === Status.loading &&
        <Spinner />
      }
      {
        Items.getItems().map((i, index) => {
          return <Customer
              key={`CustomerListItem-${i.id}`}
              className={`hover ${props.selected === i.id ? 'mark' : ''}`}
              item={i}
              onClick={props.onSelect}
              onTrash={props.onTrash || props.standalone ? async () => {
                if (!window.confirm(`${i.name} wirklich löschen?`)) { return }
                setStatus(Status.loading)
                await Items.trashItem(i.id)
                setStatus(Status.standard)
              } : undefined}
              onRemove={props.onRemove ? () => {
                if (props.onRemove) {
                  props.onRemove(i.id)
                }
              } : undefined}
              options={props.options}
            />
        })
      }
      {
        status === Status.loadMore &&
        <div className='textCenter'>
          <Spinner mini={true} />
          <div>Lade weitere Elemente</div>
        </div>
      }
    </div>
  </PlaningToolRoot>
}
