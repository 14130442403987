import { useEffect, useState } from "react"
import ItemDTO from "../../DTO/ItemDTO"
import { GenericDTO } from "../../DTO/GenericDTO"
import { EventDTO } from "../../DTO/EventDTO"
import { LexwareButtonType, LexwareDTO } from "../../DTO/LexwareDTO"
import { Check, DollarSign, DownloadCloud, Edit3, Plus, Tag, UploadCloud, X, XCircle } from "react-feather"
import lexwareLogo from '../../../assets/lexware/LexwareLogo.svg'
import lexwareLogoBlack from '../../../assets/lexware/LexwareLogoBlack.svg'
import InputB from "../../../components/InputB/InputB"
export function LexwareButton(props: {
  item: ItemDTO | GenericDTO | EventDTO,
  kind: LexwareButtonType,
  reloadTrigger?: () => void,
  displayBillList?: boolean,
  className?: string,
  personId?: string,
}) {
  const [rerender, setRerender] = useState(0)
  function reloadButton() {
    setRerender(rerender + 1)
  }
  const [lexware] = useState(new LexwareDTO({
    item: props.item,
    reloadTrigger: props.reloadTrigger,
    kind: props.kind,
    personId: props.personId,
    // reloadButton: () => {reloadButton()},
  }))

  const [renameItem, setRenameItem] = useState('')
  useEffect(() => {
    lexware.reram(props.item)
  }, [lexware, props.item])
  console.log('lexwareButton, props', props)  
      // <img style={{height: '42px'}} src={lexwareLogo} alt="LexwareLogo" className="lexwareLogo" />

  function LexwareButtons() {
    return <>
      {
        lexware.doDisplay('lexwareLogo') &&
        <img style={{height: '42px'}} src={lexwareLogo} alt="LexwareLogo" className="lexwareLogo" />
      }
      {
        lexware.doDisplay('transferToLexware') &&
        <button
          title='Auf Lexware übertragen'
          className={`w3-button`}
          onClick={async () => {
            await lexware.doTransfer()
            reloadButton()
          }}
        >
          {
            lexware.resourceUri ?
            <img style={{height: '42px'}} src={lexwareLogo} alt="LexwareLogo" className="lexwareLogo" />
            :
            <img style={{height: '42px'}} src={lexwareLogoBlack} alt="LexwareLogo" className="lexwareLogo" />
          }
        </button>
      }
      {
        props.displayBillList && lexware.doDisplay('removeLexwareConnection') &&
        <button
          title='Verbindung zu Lexware trennen'
          className={`w3-button`}
          onClick={async () => {
            if(!window.confirm('Soll die Verbindung zu Lexware wirklich getrennt werden?')) return;
            
            await lexware.removeLexwareConnection()
            reloadButton()
          }}
        ><X /></button>
      }
      {
        lexware.doDisplay('createBill') &&
        <>
          <button
            title='Angebot schreiben'
            className={`w3-button`}
            onClick={async () => {
              await lexware.createBill('offer')
              reloadButton()
            }}
          ><Tag /></button>
          <button
            title='Rechnung schreiben'
            className={`w3-button`}
            onClick={async () => {
              await lexware.createBill('bill')
              reloadButton()
            }}
          ><DollarSign /></button>
        </>
      }
    </>
  }
  // Modus if we do not have the list:
  if (!props.displayBillList) {
    return <LexwareButtons />
  }
  // Modus for displaying everything:
  return <div className={props.className || ''}>
    <div>
      <LexwareButtons />
    </div>
    {
      props.displayBillList &&
      <table>
        <tbody>
        {
          lexware.getBillList().map((billItem, index) => <tr
            key={billItem.key}
          >
            <td>
              {
                billItem.billType === 'bill' && <DollarSign />
              }
              {
                billItem.billType === 'offer' && <Tag />
              }
            </td>
            <td title='Rechnungsnummer'>
              <i>
                {billItem.voucherNumber}
              </i>
            </td>
            <td>
              {
                renameItem === billItem.key ?
                <InputB
                  returnVal={(newVal) => {
                    console.log('newVal', newVal)
                    lexware.saveBillName(billItem.key, newVal)
                    setRenameItem('')
                  }}
                  value={billItem.name}
                  delay={-1}
                  sendButton={`Speichern`}
                /> :
                <span>{billItem.name}</span>
              }
            </td>
            <td>
              ({lexware.paymentStatus2Human(billItem.paymentStatus)})
            </td>
            <td>
              {billItem.humanDate}
            </td>
            <td>
              {
                renameItem === billItem.key ? 
                <button
                  className={`w3-button`}
                  title='Umbenennen Abbrechen'
                  onClick={() => {
                    setRenameItem('')
                  }}
                ><XCircle /></button>
                :
                <button
                  className={`w3-button`}
                  title='Umbenennen'
                  onClick={() => {
                    setRenameItem(billItem.key)
                  }}
                ><Edit3 /></button>
              }
            </td>
            <td>
              <button
                className={`w3-button`}
                title='Status von Lexware abrufen'
                onClick={async () => {
                  await lexware.getBillStatus(billItem.resourceUri)
                  reloadButton()
                }}
              ><DownloadCloud /></button>
            </td>
            <td>
              <button
                className={`w3-button`}
                title='Verbindung lösen'
                onClick={async () => {
                  if (window.confirm('Verbindung wirklich lösen?')) {
                    await lexware.removeBill(billItem.resourceUri)
                    reloadButton()
                  }
                }}
              ><X /></button>
            </td>
          </tr>)
        }
        </tbody>
      </table>
    }
  </div>
}
