import { Setter } from "../../services/ComService";
import { CreateTimeStamp, DisplayDate, DisplayDateTimeFromString, DisplayDateTimeNumeric } from "../../services/DateTime";
import { parseStringFromServer } from "../../services/TextConverter";
import { EventDTO } from "./EventDTO";
import { GenericDTO } from "./GenericDTO";
import ItemCustomerDTO from "./ItemCustomerDTO";
import ItemDTO from "./ItemDTO";

type BareMinimum = {
  id: number,
  name: string,
}

type BillType = 'bill' | 'offer'

export type LexwareButtonType = 'customer' | 'bill' | 'person' | 'private'

type ExtraType = {
  humanDate: string,
  jsonDate: string,
  paymentStatus: string,
  personId: string,
  billType: BillType,
  voucherNumber: string,
}



export class LexwareDTO {
  item: ItemDTO | GenericDTO | EventDTO | BareMinimum
  reloadTrigger?: () => void
  reloadButton?: () => void
  personId?: string
  resourceUri: string = ''
  kind: LexwareButtonType

  constructor(data: {
    item: ItemDTO | GenericDTO | EventDTO | BareMinimum,
    reloadTrigger?: () => void,
    reloadButton?: () => void,
    personId?: string,
    kind: LexwareButtonType
  }) {
    this.item = data.item
    this.reloadTrigger = data.reloadTrigger
    this.reloadButton = data.reloadButton
    this.personId = data.personId
    this.kind = data.kind
    this.init()
  }

  reram(item: ItemDTO | GenericDTO | EventDTO | BareMinimum) {
    this.item = item
    this.init()
  }

  private init() {
    if (!('getProp' in this.item)) {
      return
    }
    const prop = this.item.getProp('lexware', 'id')
    if (prop && prop.value1) {
      this.resourceUri = prop.value1
    }
  }

  private giveLexwareUrl(kind?: string) {
    switch(kind || this.kind) {
      case 'customer':
        return 'spt/lexware/customer/update'
      case 'bill':
      case 'offer':
      default:
        return 'spt/lexware/bill/update'
    }
  }

  private reloadCB() {
    if (this.reloadTrigger) {
      this.reloadTrigger()
    }
  }

  private reloadButtonCB() {
    if (this.reloadButton) {
      this.reloadButton()
    }
  }

  doDisplay(key: string) {
    switch(key) {
      case 'transferToLexware':
        return this.kind === 'customer'
      case 'createBill':
        return true
      case 'removeLexwareConnection':
        return this.isTransfered()
      case 'lexwareLogo':
        return this.kind === 'bill' || this.kind === 'private'
    }
  }

  isTransfered() {
    return !!this.resourceUri
  }

  async doTransfer() {
    const url = this.giveLexwareUrl()
    const result = await Setter(url, {
      id: this.item.id
    })
    if (result.IssueList || result.error) {
      if (result.status === 404) {
        alert('Bei Lexware ist dies nicht mehr vorhanden!')
      } else {
        alert('Fehler beim Kommunizieren mit Lexware.')
      }
      console.log('Lexware Fehler: ', result)
    } else {
      console.log('lexware result', result)
      this.resourceUri = result.resourceUri // this seams wrong!
      if (this.reloadButton) {
        this.reloadButton()
      }
    }
  }

  async removeLexwareConnection() {
    if (!('getProp' in this.item)) {
      return
    }
    console.log('this.item', this.item)
    const prop = this.item.getProp('lexware', 'id')
    console.log('got prop', prop)
    if (prop) {
      await this.item.removeProp(prop.id)
      this.reloadButtonCB()
    }
  }

  async createBill(billType: BillType) {
    console.log('createBill', this)
    let localName = DisplayDateTimeNumeric(new Date())
    if (this.kind === 'bill') {
      localName = this.item.name
    }
    const userResponse = window.prompt("Möchten Sie einen Namen eingeben?", localName);
    if (userResponse !== null && userResponse.trim() !== "") {
      localName = userResponse.trim();
    }
    const paymentStatus = ''
    // const lexwareName = this.item.name
    const url = this.giveLexwareUrl(billType)
    
    const result = await Setter(url, {
      id: this.item.id,
      personId: this.personId ? parseInt(this.personId, 10) : -1,
      name: localName,
      kind: this.kind,
      type: billType,
    })
    if (result.IssueList || result.error) {
      alert('Fehler beim Kommunizieren mit Lexware. Meistens deutet dies darauf hin, dass die Firma bei Lexware gelöscht wurde.')
      console.log('Lexware Fehler: ', result)
      return
    } else {
      console.log('lexware result', result)
      // this.resourceUri = result.id // this seams wrong!
      if (this.reloadButton) {
        this.reloadButton()
      }
    }
    const lexwareId = result.resourceUri
    const voucherNumber = result.voucherNumber || ''
    if ('addProp' in this.item) {
      await this.item.addProp('lexwareBills', lexwareId, localName, this.value2Encoder({
        paymentStatus: paymentStatus,
        billType: billType,
        voucherNumber: voucherNumber,
      }))
    } else {
      const item = new ItemCustomerDTO(this.item)
      item.addProp('lexwareBills', lexwareId, localName, this.value2Encoder({
        paymentStatus: paymentStatus,
        billType: billType,
        voucherNumber: voucherNumber,
      }))
    }
    if (billType === 'offer') {
      alert(`Ein Angebot mit dem Namen "${localName}" wurde erstellt!`)
    } else {
      alert(`Eine Rechnung mit dem Namen "${localName}" wurde erstellt!`)
    }
    this.reloadButtonCB()
    this.reloadCB()
  }

  private value2Decoder(value2: string): ExtraType {
    let out: ExtraType = {
      humanDate: '',
      jsonDate: '',
      paymentStatus: '',
      personId: '',
      billType: 'bill',
      voucherNumber: '',
    }
    const parts = parseStringFromServer(value2).split(';')
    if (parts[0]) {
      console.log('value2Decoder', parts)
      out.jsonDate = parts[0]
      out.humanDate = DisplayDateTimeFromString(parts[0])
    }
    if (parts[1]) {
      out.paymentStatus = parts[1]
    }
    if (parts[2]) {
      out.personId = parts[2]
    }
    if (parts[3]) {
      out.billType = (parts[3] || 'bill') as BillType
    }
    if (parts[4]) {
      out.voucherNumber = parts[4] || ''
    }
    return out
  }

  private value2Encoder(data: {
    jsonDate?: string,
    paymentStatus: string,
    billType?: BillType,
    voucherNumber?: string,
  }) {
    let jsonDate = CreateTimeStamp()
    if (data.jsonDate) {
      jsonDate = data.jsonDate
    }
    return `${jsonDate};${data.paymentStatus};${this.personId};${data.billType || 'bill'};${data.voucherNumber || ''}`
  }

  async removeBill(key2: string) {
    if (!('removeProp' in this.item)) {
      return
    }
    const prop = this.item.getProp('lexwareBills', key2)
    if (prop) {
      await this.item.removeProp(prop.id)
      this.reloadButtonCB()
    }
  }

  async saveBillName(key2: string, name: string) {
    if (!('getProp' in this.item)) {
      return
    }
    const prop = this.item.getProp('lexwareBills', key2)
    if (prop) {
      this.item.addProp(prop.key1, prop.key2, name, prop.value2)
      this.reloadButtonCB()
    }
  }

  getBillList():
  {
    resourceUri: string,
    name: string,
    key: string,
    paymentStatus: string,
    humanDate: string,
    billType: BillType,
    voucherNumber: string
  }[]
  {
    if (!('getProps' in this.item)) {
      return []
    }
    const list = this.item.getProps('lexwareBills')
    return list.map((item) => {
      const extra = this.value2Decoder(item.value2)
      return {
        resourceUri: item.key2,
        name: parseStringFromServer(item.value1),
        key: item.key2,
        humanDate: extra.humanDate,
        paymentStatus: extra.paymentStatus,
        personId: extra.personId,
        billType: extra.billType,
        voucherNumber: extra.voucherNumber,
      }
    }).filter(i => !this.personId || this.personId === i.personId)
  }

  async getBillStatus(key2: string) {
    if (!('getProp' in this.item)) {
      return []
    }
    const prop = this.item.getProp('lexwareBills', key2)
    if (prop) {
      const url = 'spt/lexware/bill/get'
      const result = await Setter(url, {
        lexwareResource: key2
      })
      if (result.IssueList || result.error) {
        if (result.status === 404) {
          alert('Bei Lexware ist dies nicht mehr vorhanden!')
        } else {
          alert('Fehler beim Kommunizieren mit Lexware.')
        }
        console.log('Lexware Fehler: ', result)
      } else {
        console.log('lexware result', result)
        // this.resourceUri = result.id // this seams wrong!
        // TODO
        // Use the information from result!
        // save prop with new information
        const paymentStatus = result.paymentStatus || result.voucherStatus
        const extra = this.value2Decoder(prop.value2)
        await this.item.addProp(prop.key1, prop.key2, result.title, this.value2Encoder({
          jsonDate: extra.jsonDate,
          paymentStatus: paymentStatus,
          billType: extra.billType,
          voucherNumber: result.voucherNumber,
        }))
        this.reloadButtonCB()
      }
    }
  }

  paymentStatus2Human(s: string) {
    switch(s) {
      case 'draft':
        return 'Vorlage'
      case 'open':
        return 'Offen'
      case 'paid':
        return 'Bezahlt'
      case 'voided':
        return 'Abgebrochen'
      case 'balanced':
        return 'Ausgeglichen'
      case 'openRevenue':
        return 'Offener Umsatz'
      case 'openExpense':
        return 'Offene Ausgaben'
    }
    return '-'
  }
}
