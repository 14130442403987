import { useEffect, useRef, useState } from "react";
import { Plus } from "react-feather";
import InputB from "../../../components/InputB/InputB";
import { EventDTO } from "../../DTO/EventDTO";
import { ObjectKind } from "../../models/objectKinds";
import { PublishKind } from "../../models/publishKinds";
import BuildingBlocksDTO from "../../DTO/BuildingBlocksDTO";
import Spinner from "../../../components/Spinner/Spinner";
import mainservice from "../../../services/MainService";
import { MItem } from "../Menu";
import { DisplayEvent } from "./DisplayEvent";
import { DateAddDays, DisplayDate, DisplayPHPDate } from "../../../services/DateTime";
import './EventList.scss'
import { SeminarDTO } from "../../DTO/SeminarDTO";
import { PersonKind } from "../Person/PersonKind";
import PlaningToolRoot from "../PlaningToolRoot";
import BroadcastRightButton from "../../elements/BroadcastRightButton";
import { RightDrawerContent } from "../RightDrawer";
import { Status } from "../day/dayView";
import { InfinitScroll } from "../../../services/InfinitScroll";
import { IProp } from "../../DTO/PropDTO";
import { Help } from "../Help";
import { minDateSaverService } from "../../../services/MinDateSaverService";

const DisplayKinds = [
  {
    name: 'Liste',
    key: 'list',
  },
  {
    name: 'Gantt',
    key: 'gantt',
  },
  {
    name: 'Unterminiert',
    key: 'listwithoutdate',
  },
]

export default function EventList(props: {
  blacklist?: number[],
  className?: string,
  customerId?: number,
  customerLocationId?: number,
  locationId?: number,
  personId?: number,
  standalone?: boolean,
  noSearch?: boolean,
  rerenderTrigger?: number,
  selected?: number,
  onSelect?: (id: number, event: EventDTO) => void,
  noNew?: boolean,
  objectKind?: ObjectKind,
  publishKind?: PublishKind,
  allowRemove?: boolean,
  minDateFilter?: boolean,
  onlyWithoutCustomer?: boolean,
  onlyWithoutCustomerLocation?: boolean,
  onlyWithoutLocation?: boolean,
  personKind?: PersonKind,
  compact?: boolean | 'name' | 'participated',
  headline?: string,
  leads?: string,
  mode?: 'trash',
  ignoreDate?: '' | 'ignore',
  limit?: number,
}) {
  // const objectKind = props.objectKind ? props.objectKind : ObjectKind.event
  const [objectKind, setObjectKind] = useState(props.objectKind ? props.objectKind : ObjectKind.event)
  const [publishKind, setPublishKind] = useState(props.publishKind === undefined ? PublishKind.isTemplate : props.publishKind)
  const [Needle, setNeedle] = useState('')
  const [status, setStatus] = useState(Status.loading)
  const [displayKind, setDisplayKind] = useState('list')
  const [buildingBlocksDTO] = useState(new BuildingBlocksDTO({
    customerId: props.customerId || -1,
    customerLocationId: props.customerLocationId || -1,
    locationId: props.locationId || -1,
    personId: props.personId || -1,
    onlyWithoutCustomer: !!props.onlyWithoutCustomer,
    onlyWithoutCustomerLocation: !!props.onlyWithoutCustomerLocation,
    onlyWithoutLocation: !!props.onlyWithoutLocation,
    personKind: props.personKind,
    compact: props.compact,
    leads: props.leads,
    mode: props.mode || 'normal',
    minDate: (props.minDateFilter) ? DisplayPHPDate(minDateSaverService.get()) : 'ignore',
    publishKind: publishKind,
    ignoreDate: props.ignoreDate,
    limit: props.limit,
  }));
  const [minDate, setMinDate] = useState(minDateSaverService.get())
  const [infinitScroll] = useState(new InfinitScroll({
    ref: useRef(null),
    cb: async () => {
      // What to do, if we are at the end of the list?
      const newPage = infinitScroll.incrementAndLock()
      if (newPage === -1) { return }
      setStatus(Status.loadMore)
      const newElementCount = await buildingBlocksDTO.setElementKindPage(infinitScroll.getPage(), undefined, publishKind)
      if (newElementCount) {
        infinitScroll.unlock()
      }
      setStatus(Status.standard)
      // Now load new content - then set Status to "standard" and unlock
      // If nothing is loaded - lock permanently
    }
  }))
  function setGlobMinDate(d: Date) {
    minDateSaverService.set(d)
    setMinDate(d)
    infinitScroll.unlock()
  }
  useEffect(() => {
    const oK = props.objectKind || ObjectKind.event
    setObjectKind(oK)
    const pK = props.publishKind === undefined ? PublishKind.isTemplate : props.publishKind
    setPublishKind(pK)
    infinitScroll.setPage(0)
    setStatus(Status.loading)
    // Lade die Inhalte entsprechend des Object Kinds
    buildingBlocksDTO.setElementKindPage(0, oK, pK).then(() => {
      setStatus(Status.standard);
    });
  }, [props.objectKind, props.publishKind])
  useEffect(() => {
    setStatus(Status.loading)
    if (props.minDateFilter) {
      buildingBlocksDTO.minDate = DisplayPHPDate(minDate)
    }
    buildingBlocksDTO.setElementKindPage(0, objectKind, publishKind).then(() => {
      setStatus(Status.standard);
    });
  }, [props.rerenderTrigger]);
  const items = buildingBlocksDTO.ShowEvents()
  const prepareGantt = buildingBlocksDTO.prepareGantt(items)
  const displayItems = items.filter((item) => {
    if (!props.blacklist || props.blacklist.length === 0 || props.blacklist.every((id) => id !== item.id)) {
      return true
    }
    return false
  }).map((item, index) => {
    const displayEvent = <DisplayEvent
      key={`DisplayEvent-${item.id}-${index}`}
      item={item as EventDTO}
      onClick={props.onSelect}
      onDublicate={props.standalone ? async () => {
        if (!window.confirm(`Wirklich Kopie von ${item.getName()} speichern?`)) { return }
        setStatus(Status.loading)
        await buildingBlocksDTO.cloneItem(item.id, objectKind)
        setStatus(Status.standard)
      } : undefined }
      onDublicateAsDesign={props.standalone && objectKind === ObjectKind.event ? async () => {
        if (!window.confirm(`Wirklich Vorlage von ${item.getName()} erstellen?`)) { return }
        setStatus(Status.loading)
        // "isSeminar" is wrong in theory, but everybody is doing it - so correcting this is a bigger efford, because without "isSeminar" a template will not be displayed...
        await buildingBlocksDTO.cloneItem(item.id, ObjectKind.seminar, PublishKind.isSeminar)
        setStatus(Status.standard)
      } : undefined }
      onTrash={props.standalone ? async () => {
        // if (!window.confirm(`${item.name} wirklich löschen?`)) { return }
        if (!(await item.trashPreflight('delete'))) { return }
        if (item.getAppointments().length > 1 && !window.confirm(`${item.name} besteht aus mehreren Teilen. Alle Teile würden somit gelöscht. Trotzdem löschen?`)) { return }
        setStatus(Status.loading)
        await item.bakeDowns()
        await buildingBlocksDTO.removeItem(item.id)
        await item.seminarTrash()
        setStatus(Status.standard)
      } : undefined}
      onUntrash={props.standalone ? async () => {
        setStatus(Status.loading)
        await buildingBlocksDTO.removeItem(item.id)
        await item.unTrash()
        setStatus(Status.standard)
      } : undefined}
      onRemove={props.allowRemove ? async () => {
        setStatus(Status.loading)
        await buildingBlocksDTO.removeItem(item.id)
        const e = new EventDTO(item)
        if (props.locationId) {
          await e.unsetLocation()
        } else if (props.personId) {
          await e.removePerson(props.personId)
        } else if (props.customerLocationId) {
          await e.unsetCustomerLocation()
        } else if (props.customerId) {
          await e.unsetCustomer()
        }
        setStatus(Status.standard)
      } : undefined}
      compact={props.compact}
      personId={props.personId}
    />
    if (displayKind === 'gantt') {
      const pg = prepareGantt.items[index]
      let beforeCells = []
      let afterCells = []
      let startDay = new Date(prepareGantt.min)
      for (let i = 0, m = pg?.cellsBefore || 0; i < m; i++) {
        beforeCells.push({
          date: DateAddDays(startDay, i)
        })
      }
      for (let i = 0, m = pg?.cellsAfter || 0; i < m; i++) {
        afterCells.push({
          date: DateAddDays(startDay, (pg?.cellsBefore || 0) + (pg?.span || 0) + i)
        })
      }
      return <tr>
        {
          beforeCells.map((bc, index) => {
            return <td
              key={`gantt-${item.id}-before-${index}`}
              title={DisplayDate(bc.date)}
            ></td>
          })
        }
        <td
          colSpan={pg?.span}
        >
          {displayEvent}
        </td>
        {
          afterCells.map((bc, index) => {
            return <td
              key={`gantt-${item.id}-after-${index}`}
              title={DisplayDate(bc.date)}
            ></td>
          })
        }
      </tr>
    } else {
      return displayEvent
    }
  })

  // Select the right Menu item:
  let menuItem = MItem.planer
  if (props.mode === 'trash') {
    menuItem = MItem.trash
  } else if (objectKind === ObjectKind.seminar) {
    menuItem = MItem.designer
  } else if (props.leads === 'leads') {
    menuItem = MItem.leads
  }

  return <PlaningToolRoot standalone={props.standalone} menuItem={menuItem}>
    { (props.standalone || props.headline) ?
      <div className='contentHeader'>
        {
          props.headline ? <h2>{props.headline}</h2> : <h1>{objectKind === ObjectKind.seminar ? 'Veranstaltungsdesigns' : (props.leads === 'leads' ? 'Leads' : 'Veranstaltungen')}</h1>
        }
        <TrashMenu
          menuItem={menuItem}
          objectKind={objectKind}
        />
        <div className='flex justify-content-space-between w100 align-items-center flex-gap align-items-bottom'>
          {
          !props.noSearch &&
            <>
              <Help>
                <h2>Suchfunktionen im Überblick</h2>
                <div>Einfach tippen und ein paar Sekunden warten. Dann werden automatisch die passenden Ergebnisse geladen.</div>
                {
                  objectKind === ObjectKind.event && 
                  <div>
                    <h3>Sonderfunktionen</h3>
                    <ul>
                      <li>Um Nach Trainern zu filtern die Trainer E-Mail eingeben</li>
                      <li>Um nach Veranstaltungsnummern zu suchen die Rautetaste (#) Drücken gefolgt von den ersten Ziffern. Beispiel: <pre>#2024</pre></li>
                      <li>Um nach Angebotsnummern zu suchen mit dem Dollarzeichen beginnen und ebenfalls die ersten Ziffern eintippen. Beispiel: <pre>$42</pre> </li>
                    </ul>
                    <h3>Achtung</h3>
                    <div>Der Datumsfilter auf der Rechtenseite beschränkt die gefundenen Datensätze. Es werden nur Daten angezeigt die folgende Bedingungen erfüllen:
                      <li>Das Enddatum der Veranstaltung is neuer als das eingestellte Datum</li>
                      <li>Das Enddatum der Veranstaltung wurde noch nicht gesetzt</li>
                    </div>
                  </div>
                }
              </Help>
              <InputB
                className='w3-border'
                returnVal={async (val) => {
                  setStatus(Status.loading)
                  let localNeedle = (val === undefined) ? '' : val
                  let props: IProp[] = []

                  // Search by Unique Event Id
                  const uniqueEventIdScope = /#([0-9a-zA-Z_-]+) ?/
                  const uniqueEventIdInfoRaw = localNeedle.match(uniqueEventIdScope)
                  if (uniqueEventIdInfoRaw) {
                    localNeedle = localNeedle.replace(uniqueEventIdScope, '')
                    props.push({id: -1, key1: 'data', key2: 'uniqueEventId', value1: uniqueEventIdInfoRaw[1], value2: ''})
                  }

                  // Search by Offer ID
                  const offerIdScope = /\$([0-9a-zA-Z_-]+) ?/
                  const offerIdInfoRaw = localNeedle.match(offerIdScope)
                  if (offerIdInfoRaw) {
                    localNeedle = localNeedle.replace(offerIdScope, '')
                    props.push({id: -1, key1: 'data', key2: 'offerId', value1: offerIdInfoRaw[1], value2: ''})
                  }

                  await buildingBlocksDTO.setElementKindPage(0, objectKind, publishKind, localNeedle, props)
                  setNeedle(val)
                  setStatus(Status.standard)
                }}
                placeholder={`Tippen um zu suchen oder neu anzulegen ... ${objectKind === ObjectKind.event ? 'Um Nach Trainern zu filtern die Trainer E-Mail eingeben. Veranstaltungsnummer mit # beginnen.' : ''}`}
              />
            </>
          }
          {
            props.minDateFilter && minDate &&
            <div className='EditField floatLeft'>
              <label className={'EditFieldLabel'}>
                Ab
              </label>
              <InputB
                value={DisplayPHPDate(minDate)}
                returnVal={(value: string) => {
                  setStatus(Status.loading)
                  buildingBlocksDTO.minDate = value
                  setGlobMinDate(new Date(value))
                  buildingBlocksDTO.setElementKindPage(0, objectKind, publishKind).then(() => {
                    setStatus(Status.standard);
                  });
                }}
                type='date'
              />
            </div>
          }
        </div>
        {
          props.standalone && objectKind === ObjectKind.event && props.mode !== 'trash' &&
          <div className='flex w100'>
            {
              DisplayKinds.map(i => <button
                  key={`display-kind-${i.key}`}
                  onClick={() => {
                    if (i.key === 'listwithoutdate') {
                      setMinDate(null)
                      buildingBlocksDTO.minDate = ''
                      infinitScroll.setPage(0)
                      infinitScroll.unlock()
                      setStatus(Status.loading);
                      buildingBlocksDTO.setElementKindPage(0, undefined, publishKind).then(() => {
                        setStatus(Status.standard);
                      });
                    }
                    if(i.key !== 'listwithoutdate' && minDate === null) {
                      const newMinDate = minDateSaverService.get()
                      setMinDate(newMinDate)
                      buildingBlocksDTO.minDate = DisplayPHPDate(newMinDate)
                      infinitScroll.setPage(0)
                      infinitScroll.unlock()
                      setStatus(Status.loading);
                      buildingBlocksDTO.setElementKindPage(0, undefined, publishKind).then(() => {
                        setStatus(Status.standard);
                      });
                    }
                    
                    setDisplayKind(i.key)
                  }}
                  className={`tab-button ${i.key === displayKind ? 'tab-active' : ''}`}
                >{i.name}</button>
              )
            }
            
          </div>
        }
        {
          Needle !== '' &&
          <button
            className='positiveButton'
            onClick={async () => {
              // If this is Event - we will call a Wizard, otherwise we just create new and visit the new Design:
              if (objectKind === ObjectKind.event) {
                setStatus(Status.new)
                return
              }
              const design = new SeminarDTO({
                id: -1,
                publishKind: publishKind
              })
              await design.setName(Needle)
              mainservice.navTo([['view', 'spVeranstaltungsDesigner'], ['id', design.id]])
            }}
          ><Plus /></button>
        }
      </div> : null
    }
    <div
      ref={infinitScroll.getRef()}
      className={`EventList ${props.className || ''} ${displayKind === 'gantt' ? 'DisplayStyleGantt' : 'DisplayStyleList'}`}
    >
      {
          status === Status.loading &&
          (
            props.compact === 'participated' ? 
            <Spinner mini /> : <Spinner />
          )
        }
      {
        status === Status.new &&
        <CreateEvent
          cancel={() => {
            setStatus(Status.standard)
          }}
          customerId={props.customerId || -1}
          customerLocationId={props.customerLocationId || -1}
          locationId={props.locationId || -1}
          personId={props.personId || -1}
          name={Needle}
          onCreate={props.onSelect}
        />
      }
      <div>
        { props.noSearch === false &&
          <InputB
            className='w3-border'
            returnVal={async (val) => {
              setStatus(Status.loading)
              const localNeedle = (val === '') ? undefined : val
              await buildingBlocksDTO.setElementKindPage(0, objectKind, publishKind, localNeedle)
              setNeedle(val)
              setStatus(Status.standard)
            }}
            placeholder={`Tippen um zu suchen oder neu anzulegen ... ${objectKind === ObjectKind.event ? 'Um Nach Trainern zu filtern die Trainer E-Mail eingeben.' : ''}`}
          />
        }
      </div>
      <div
        className='EventListList flex-grow'
      >
        { (displayKind === 'list' || displayKind === 'listwithoutdate') &&
          <>
            {props.standalone &&
              <EventHeader />
            }
            {displayItems}
          </>
        }
        {
          displayKind === 'gantt' &&
          <div  className='ganttRoot'>
            <table>
              {displayItems}
            </table>
          </div>
        }
        {
          status !== Status.loading && buildingBlocksDTO.showItems.length === 0 &&
          <div className='w100 w3-padding textCenter'>Keine Veranstaltungen vorhanden</div>
        }
        {
          status === Status.loadMore &&
          <div className='textCenter'>
            <Spinner mini={true} />
            <div>Lade weitere Elemente</div>
          </div>
        }
      </div>
    </div>
  </PlaningToolRoot>
}

function CreateEvent(props: {
  cancel: () => void,
  customerId?: number,
  customerLocationId?: number,
  locationId?: number,
  personId?: number,
  name?: string,
  onCreate?: (id: number, item: EventDTO) => void,
}) {
  const [name, setName] = useState(props.name || '')
  const [seminarId, setSeminarId] = useState(-2)
  const [templateName, setTemplateName] = useState('Keine Vorlage gewählt')
  const broadCastBackKeySeminarAdd = `EventSeminarAddFor-new`;
  mainservice.registerToBroadcast(`EventEdit-new`,
    async (key: string, _value: any) => {
      if (key === broadCastBackKeySeminarAdd) {
        setSeminarId(_value.id)
        setTemplateName(_value.name)
      }
    }
  );
  return <div
    className='CreateEvent w3-padding w3-border w3-border-green'
  >
    <h2>Neues Event erstellen</h2>
    <label>Name</label>
    <InputB
      className='EventListEditName'
      returnVal={(v) => setName(v)}
      value={name}
    />
    <BroadcastRightButton
        contentType={RightDrawerContent.selectSeminar}
        broadCastBackKey={broadCastBackKeySeminarAdd}
        title={`Veranstaltungsvorlage wählen`} />
    <div className='w3-margin'>{templateName}</div>
    
    <button
      className='w3-button w3-green'
      onClick={async () => {
        const event = new EventDTO({id: -1})
        await event.setName(name)
        if (seminarId > -1) {
          await event.addUp(seminarId)
        }
        if ((props.customerId !== undefined) && props.customerId > -1) {
          await event.addCustomer(props.customerId)
        }
        if ((props.customerLocationId !== undefined) && props.customerLocationId > -1) {
          await event.addCustomer(props.customerLocationId)
        }
        if ((props.locationId !== undefined) && props.locationId > -1) {
          await event.setLocation(props.locationId)
        }
        if ((props.personId !== undefined) && props.personId > -1) {
          await event.addPerson(props.personId)
        }
        if (props.onCreate) {
          props.onCreate(event.id, event)
        }
        mainservice.navTo([['view', 'spVeranstaltungsDetails'], ['id', event.id]])
      }}
    >Speichern</button>
    <button className='w3-button w3-grey' onClick={props.cancel}>Abbrechen</button>

  </div>
}

function EventHeader() {
  if (mainservice.nav.getVal('view') === 'spVeranstaltungsPlaner' || mainservice.nav.getVal('view') === 'spVeranstaltungsLeads') {
    return <div className='EventHeader'>
      <div className='th'>Veranstaltungsname</div>
      <div className='th'>Auftragsstatus</div>
      <div className='th'>Event Art</div>
      <div className='th'>Abrechnungsstatus</div>
      
      { false && <div className='th'>Fertigstellungs Prio</div> }
      <div className='th'>Kunde</div>
      <div className='th'>BTC</div>
      <div className='th'></div>
    </div>
  }
  
  if (mainservice.nav.getVal('view') === 'spVeranstaltungsDesigns') {
    return <div className='EventHeader'>
      <div className='th'>Designname</div>
      <div className='th'>Themen</div>
      <div className='th'>Fertigstellung</div>
      <div className='th'></div>
    </div>
  }
  return null
}

function TrashMenu(props: {
  menuItem: MItem,
  objectKind: ObjectKind,
}) {
  const [objectKind, setObjectKind] = useState(props.objectKind)
  useEffect(() => {
    setObjectKind(props.objectKind)
  }, [props.objectKind])

  const TrashItems = [
    {
      name: 'Veranstaltungen',
      view: 'spPapierkorb',
      objectKind: ObjectKind.event,
    },
    {
      name: 'Designs',
      view: 'spPapierkorbDesigns',
      objectKind: ObjectKind.seminar,
    },
  ]
  if (props.menuItem !== MItem.trash) { return null }
  return <div className="flex w100">
    {
      TrashItems.map(tI => <button
        key={`TrashItemButton${tI.objectKind}`}
        className={`tab-button ${objectKind === tI.objectKind ? 'tab-active' : ''}`}
        onClick={() => {
          mainservice.navTo([['view', tI.view]])
        }}
      >{tI.name}</button>)
    }
  </div>
}
