import { useEffect, useState } from "react";
import { GenericDTO } from "../../planningTool/DTO/GenericDTO";
import ItemDTO from "../../planningTool/DTO/ItemDTO";
import { suggestNextEventIds, testEventId } from "./UniqueEventIdHelper";

export default function UniqueEventIdInput(props: {
  item: GenericDTO | ItemDTO,
  returnVal: (value: string) => void,
  value?: string,
  className?: string,
  disabled?: boolean,
}) {
  const [timeoutHolder, setTimeoutHolder] = useState(setTimeout(() => {}, 500))
  const [currentValue, setCurrentValue] = useState(props.value || '')
  const [lastSendValue, setLastSendValue] = useState(props.value || '')
  const [suggestions, setSuggestions] = useState<string[]>([])
  const [item, setItem] = useState(props.item)
  const [saveFailed, setSaveFailed] = useState(false)
  useEffect(() => {
    setItem(props.item)
    suggestNextEventIds(props.item, props.value || '').then((res) => {
      setSuggestions(res)
    })
  }, [props.item, props.value])
  let classColor = ''
  if (currentValue !== lastSendValue) {
    classColor = 'w3-yellow'
    if (saveFailed) {
      classColor = 'w3-red'
    }
  }
  return <>
    <input
        className={`w3-input ${props.className || ''} ${classColor}`}
        value={currentValue}
        placeholder={''}
        list='eventIdSuggestions'
        onKeyDown={async (event) => {
          if (event.key === 'Tab') {
            event.preventDefault();
            // Handle tab key press
            if (suggestions.length > 0) {
              const value = suggestions[0]
              setCurrentValue(value)
              if (await testEventId(item, value, false)) {
                await props.returnVal(value)
                setLastSendValue(value)
                setSaveFailed(false)
              } else if (value.length === 6) {
                setSaveFailed(true)
              }
            }
          }
        }}

        onChange={(event) => {
          const value = event.target.value
          setCurrentValue(value)
          clearTimeout(timeoutHolder)
          setTimeoutHolder(setTimeout(() => {
            if (value !== lastSendValue) {
              // props.returnVal(value)
              suggestNextEventIds(item, value).then(async (res) => {
                setSuggestions(res)
                if (await testEventId(item, value, false)) {
                  await props.returnVal(value)
                  setLastSendValue(value)
                  setSaveFailed(false)
                } else if (value.length === 6) {
                  setSaveFailed(true)
                }
              })
            }
          }, 500))
        }}
        disabled={!!props.disabled}
      />
      <datalist id="eventIdSuggestions">
        {
          suggestions.map((value) => <option key={`uei-sugg-${value}`} value={value} />)
        }
      </datalist>
  </>
}
