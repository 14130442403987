import { start } from "repl"
import { GenericDTO } from "../../planningTool/DTO/GenericDTO"
import ItemDTO from "../../planningTool/DTO/ItemDTO"
import { Setter } from "../../services/ComService"

type EventIdType = {
  id: number,
  value1: string,
  parentId: number
}
async function getOtherEventIds(needle: string): Promise<EventIdType[]> {
  const result = await Setter('spt/getPropsByKey1Key2ValueStart', {
    key1: 'data',
    key2: 'uniqueEventId',
    value1: needle
  })
  if (result.results) {
    return result.results
  }
  return []
}

export async function suggestNextEventIds(item: GenericDTO | ItemDTO, startString: string): Promise<string[]> {
  if (await testEventId(item, startString, true)) {
    // all okay, we do not need to sugest anything
    return []
  }
  if (startString.length < 4) {
    if (item instanceof GenericDTO) {
      const dateScope = /^[0-9][0-9]([0-9][0-9])-([0-9][0-9])/
      const startDate = item.startdate
      if (startDate && startDate.search(dateScope) !== -1) {
        const startDateMatch = startDate.match(dateScope) || ['wholestring', 'YY', 'MM']
        startString = startDateMatch[1] + startDateMatch[2]
      } else {
        // startString = ''
        return []
      }
    }
  } else if (startString.length > 4) {
    startString = startString.slice(0, 4)
  }
  const otherEventIdsRaw = (await getOtherEventIds(startString)).filter(item => item.value1.length === 6).sort((a, b) => a < b ? -1 : 1)
  const otherEventIds = otherEventIdsRaw.map(item => item.value1)
  if (otherEventIds.length === 0) {
    const out = startString + '01'
    return [out]
  }
  const biggestId = otherEventIds[otherEventIds.length - 1] || 'YYMM01'
  const nextId = biggestId.slice(0, 4) + (Number(biggestId.slice(4, 6)) + 1).toString().padStart(2, '0')
  
  return [nextId]
}

export async function testEventId(
  item: GenericDTO | ItemDTO,
  eventId: string,
  dour: boolean,
  eventIds?: EventIdType[]
) {
  if (!dour && eventId === '') { return true }
  if (eventId.length === 6) {
    if (eventIds === undefined) {
      eventIds = await getOtherEventIds(eventId)
    }
    if (eventIds.find(eid => eid.value1 === eventId && eid.parentId !== item.id)) {
      return false
    }
    return true
  }
  return false
}
