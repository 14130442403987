import { CheckSquare, Edit, Mail, Square, Trash, X } from "react-feather"
import ItemPersonDTO from "../../DTO/ItemPersonDTO"
import mainservice from "../../../services/MainService"
import { EventDTO } from "../../DTO/EventDTO"
import { useEffect, useState } from "react"
import './Person.scss'
import { PersonBubble } from "./PersonBubble"
import EventList from "../Event/EventList"
import { PublishKind } from "../../models/publishKinds"
import InputB from "../../../components/InputB/InputB"
import { DisplayCustomerName } from "../Customer/Customer";
import ItemCustomerDTO from "../../DTO/ItemCustomerDTO"
import { LexwareButton } from "../Lexware/LexwareButton"

export default function Person(props: {
  item: ItemPersonDTO,
  onClick?: (id: number, item: ItemPersonDTO) => void,
  className?: string,
  onTrash?: (id: number) => void,
  onRemove?: () => void,
  propTrainerTagParent?: EventDTO,
  personTagParent?: EventDTO,
  compact?: false | true | 'medium' | 'bubble' | 'joinedEvents',
  prefix?: string,
  select?: () => void,
  selected?: boolean,
  cb?: () => void,
}) {
  const item = props.item
  const name = <>{item.getDisplayName()}</>
  const [selected, setSelected] = useState(props.selected || false)
  useEffect(() => {
    if (props.selected !== undefined) {
      setSelected(props.selected)
    }
  }, [props.selected])
  if (props.compact === 'bubble') {
    return <PersonBubble item={item} onClick={props.onClick} />
  }
  if (props.compact === 'medium') {
    return <div
      className={`STPerson PTListItem flex ${props.className || ''}`}
      onClick={() => {
        console.log('person click')
        if (props.select) {
          console.log(' select')
          props.select()
        } else if (props.onClick) {
          props.onClick(item.id, item)
          mainservice.navTo([['view', 'spPersonEdit'], ['id', item.id]])
          return
        }
      }}
    >
      { props.select &&
        <button className={`w3-button`}>
          { selected ? <CheckSquare /> : <Square /> }
        </button>
      }
      <div
        className={`w3-button w100 textLeft`}>
        
        <div>{name}</div>
        {
          item.customers && item.customers.length > 0 &&
          <>
            {item.customers.map((c, index) =>{
              return <div
                key={`person-customer-${item.id}-${c.id}`}
                className='regularWeight'
              >
                  {c.name}
                
              </div>
            })}
          </>
        }
      </div>
    </div>
  }
  if (props.compact === 'joinedEvents') {
    return <div className={`w100 flex STPerson PTListItem ${props.className || ''}`}>
      <div className='w3-padding floatLeft w40'>
        <div>
          {item.getDisplayName()}
        </div>
        <EventTag
          person={{id: item.id}}
          parent={props.personTagParent}
          className='w3-margin-left w3-margin-top w3-margin-bottom'
          standardValue='none'
          key1='participationProp'
          types={ParticipationTypes}
          cb={props.cb}
        />
        {
          props.personTagParent && props.personTagParent.getPropV1('data', 'individualBookable') === '1' &&
          <EventTag
            person={{id: item.id}}
            parent={props.personTagParent}
            className='w3-margin-left w3-margin-top w3-margin-bottom'
            standardValue='none'
            key1='personPayedProp'
            types={PaymentTypes}
          />
        }
        {
          props.personTagParent &&
          <>
          <div className='w3-margin-top'>
            <PersonEventNotesDisplay
              key1={"personNotes"}
              name={"Notizen"}        
              personId={item.id}
              event={props.personTagParent}
            />
          </div>
          <div className='w3-margin-top'>
            <PersonEventNotesDisplay
              key1={"personCancelNotes"}
              name={"Absagegrund"}        
              personId={item.id}
              event={props.personTagParent}
            />
          </div>
          </>
        }
      </div>
      <div className='floatLeft w60'>
        <EventList
          personId={item.id}
          onSelect={() => {}}
          compact={'participated'}
          blacklist={[props.personTagParent?.id || -1]}
          publishKind={PublishKind.isSeminar}
          ignoreDate='ignore'
        />
      </div>
    </div>
  }
  if (props.compact) {
    return <span
      onClick={() => {
        mainservice.navTo([['view', 'spPersonEdit'], ['id', item.id]])
      }}
    >{props.prefix || ''}{name}</span>
  }
  return <div className={`STPerson PTListItem flex justify-content-space-between ${props.className || ''}`}>
    { props.select &&
      <button className={`w3-button`} onClick={props.select} >
        { selected ? <CheckSquare /> : <Square /> }
      </button>
    }
    <button
      className={`w3-button flex-grow textLeft`}
      onClick={() => {
        if (props.onClick) {
          props.onClick(item.id, item)
          return
        }
        mainservice.navTo([['view', 'spPersonEdit'], ['id', item.id]])
      }}
    >{name}</button>
    <div className='width500px flex'>
      <EventTag
        person={item}
        parent={props.personTagParent}
        className='w3-margin-left w3-margin-top w3-margin-bottom'
        standardValue='none'
        key1='participationProp'
        types={ParticipationTypes}
      />
      {
        props.personTagParent && props.personTagParent.getPropV1('data', 'individualBookable') === '1' &&
        <EventTag
          person={{id: item.id}}
          parent={props.personTagParent}
          className='w3-margin-left w3-margin-top w3-margin-bottom'
          standardValue='none'
          key1='personPayedProp'
          types={PaymentTypes}
        />
      }
      <div>
        <EventTag
          person={item}
          parent={props.propTrainerTagParent}
          className='w3-margin-left w3-margin-top w3-margin-bottom'
          standardValue='trainer'
          key1='trainerProp'
          types={TrainerTypes}
        />
        <ProjectOwnerTag
          person={item}
          parent={props.propTrainerTagParent}
        />
      </div>
      <div className='flex-grow'>
      {
        item.customers && item.customers.length > 0 &&
          (
            item.customers.map((c, index) => {
              console.log('PERSON CUSTOMER', c)
              // eslint-disable-next-line react/jsx-no-undef
              return <div className='flex'>
                <button
                  className='w3-button stretch textLeft flex-grow'
                  onClick={() => {
                    if (item.customers.length > 1) {
                      mainservice.navTo([['view', 'spCustomerEdit'], ['id', c.id]])
                    }
                  }}
                >
                  <DisplayCustomerName
                    key={`person-customer-${item.id}-${c.id}`}
                    item={c as ItemCustomerDTO}
                    // lazy
                    // options={[['customerShowCityAndAddress', 'yes']]}
                    
                    options={[['customerShowCityAndAddress', 'yes']]}
                  />
                </button>
                <LexwareButton
                  item={c as ItemCustomerDTO}
                  kind='person'
                  personId={`${item.id}`}
                  reloadTrigger={() => {
                    window.location.reload();

                  }}
                />
              </div>
            })
          )
        }
      </div>
    </div>
    {
      item.email ?
      <button
        className={`w3-button `}
        onClick={(e) => {
          e.preventDefault();
          window.location.href = `mailto:${item.email}`;
        }}
      ><Mail /></button>
      :
      <button
        className='w3-button inactive'
        title="Keine E-Mail Adresse bekannt"
      >
        <Mail/>
      </button>
    }
    {
      props.onTrash &&
      <button
        className={`w3-button`}
        onClick={() => {
          if (props.onTrash) {
            props.onTrash(item.id)
          }
        }}
      ><Trash /></button>
    }
    {
      props.onRemove &&
      <button
        className={`w3-button`}
        onClick={props.onRemove}
      ><X /></button>
    }
  </div>
}

type TagItemType = {key: string, name: string}

export const TrainerTypes: TagItemType[] = [
  {
    key: 'managementCoach',
    name: 'Management Coach',
  },
  {
    key: 'seniorCoach',
    name: 'Senior Coach',
  },
  {
    key: 'managementTrainer',
    name: 'Management Trainer*in',
  },
  {
    key: 'seniorTrainer',
    name: 'Senior Trainer*in',
  },
  {
    key: 'trainer',
    name: 'Trainer*in',
  },
  {
    key: 'assitant',
    name: 'Assistent',
  },
]


export const ParticipationTypes: TagItemType[] = [
  {
    key: 'none',
    name: 'nicht teilgenommen',
  },
  {
    key: 'registered',
    name: 'angemeldet',
  },
  {
    key: 'participated',
    name: 'teilgenommen',
  },
  {
    key: 'canceled',
    name: 'storniert',
  },
]

export const PaymentTypes: TagItemType[] = [
  {
    key: 'none',
    name: 'nicht bezahlt',
  },
  {
    key: 'payed',
    name: 'bezahlt',
  }
]

// Tags which are saved for a person in combination with an event
// key1 examples:
//  trainerProp = Kind of Trainer
//  participationProp = Kind of participation
export function EventTag(props: {
  person: {id: number},
  parent?: EventDTO | ItemPersonDTO,
  standardValue: string,
  key1: string,
  key2?: string,
  className?: string,
  types: TagItemType[],
  cb?: () => void,
}) {
  const parent = props.parent
  const person = props.person
  const key1 = props.key1 //'trainerProp'
  const key2 = props.key2 || `${person ? person.id : -1}`
  const standardValue = props.standardValue
  const [currentValue, setCurrentValue] = useState(parent ? parent.getPropV1(key1, key2) : standardValue || standardValue)
  if (!parent) {
    return null
  }
  
  return <select
      value={currentValue}
      onChange={async (event) => {
        const newValue = event.target.value
        setCurrentValue(newValue)
        await parent.addProp(key1, key2, newValue)
        props.cb && props.cb()
      }}
      className={props.className || ''}
    >
      {
        props.types.map((tt, index) => {
          return <option
            key={`${key1}-${person.id}-${tt.key}-${index}`}
            value={tt.key}
          >{tt.name}</option>
        })
      }
    </select>
}

export function PersonEventNotesDisplay(props: {
  personId: number,
  event: EventDTO,
  className?: string,
  name: string,
  key1: string,
}) {
  enum Status {
    start,
    edit,
    view,
    saving,
  }
  const key1 = props.key1
  console.log('### key1', key1)
  const key2 = `${props.personId}`
  const [status, setStatus] = useState(Status.start)
  const [content, setContent] = useState(props.event.getPropV1(key1, key2) || '')
  if (status === Status.start) {
    return <div className='lightFont'>
      <label
        onClick={() => {
          setStatus(Status.edit)
        }}
      >
        {props.name}
      </label>
      <div className={content ? 'w3-white w3-padding w3-margin' : ''} dangerouslySetInnerHTML={{__html: content}}></div>
    </div>
  }
  if (status === Status.edit) {
    return <div>
      <button
        className={`w3-button`}
        onClick={() => {
          setStatus(Status.start)
        }}
      >{props.name} schließen</button>
      <InputB
        value={content}
        returnVal={(content: string) => {
          console.log('save the content', content, key1, key2)
          props.event.addProp(key1, key2, content)
          setContent(content)
        }}
        type={'textarea'}
        multiline
      />
    </div>
  }
  return null
}

export function EventTagDisplay(props: {
  personId: number,
  event: EventDTO,
  className?: string,
}) {
  const key1 = 'participationProp'
  const key2 = `${props.personId}`
  const participationKey = props.event.getPropV1(key1, key2) || 'none'
  const participationName = ParticipationTypes.find(pt => pt.key === participationKey)?.name || 'nicht teilgenommen'
  const currentId = mainservice.nav.getVal('id') as number
  const showParticipationDD = props.event.id === currentId

  return <div className={`${props.className || ''}`}>
    {showParticipationDD ?
      <>
        
      </>
      : participationName
    }
  </div>
}

export function ProjectOwnerTag(props: {
  person: ItemPersonDTO,
  parent?: EventDTO
}) {
  const parent = props.parent
  const person = props.person
  const key1 = 'projectOwner'
  const key2 = `${person ? person.id : -1}`
  const [currentValue, setCurrentValue] = useState(parent ? parent.getPropV1(key1, key2) : 'no')
  if (!parent) {
    return null
  }
  function click () {
    const newValue = currentValue === 'yes' ? 'no' : 'yes'
    setCurrentValue(newValue)
    if (parent) {
      parent.addProp(key1, key2, newValue)
    }
  }
  return <>
  <button
    className='invisble-button'
    onClick={click}
    >
    {
      currentValue === 'yes' ? <CheckSquare /> : <Square />
    }
    <span className='text-after-icon'>
      Projektverantwortlich
    </span>
    </button>
  </>
}
