import { Copy, Trash, X } from "react-feather"
import ItemCustomerDTO from "../../DTO/ItemCustomerDTO"
import mainservice from "../../../services/MainService"
import { OptionArray, OptionArrayHasValue, OptionArrayValue } from "../../../services/OptionArrayParser"
import { useEffect, useState } from "react"
import { Status } from "../day/dayView"
import { countryDisplay } from "../../../services/country"
import { LexwareButton } from "../Lexware/LexwareButton"

export default function Customer(props: {
  item: ItemCustomerDTO,
  onClick?: (id: number, item: ItemCustomerDTO) => void,
  className?: string,
  onTrash?: (id: number) => void,
  onRemove?: () => void,
  options?: OptionArray,
}) {
  const item: ItemCustomerDTO = props.item
  const customerPersonLexwareId = OptionArrayValue(props.options, 'customerPersonLexware')
  return <div
    className={`STCustomer PTListItem ${props.className || ''}`}
  >
    <div className={`flex justify-content-space-between`}>
      <button
        className={`w3-button flex-grow textLeft`}
        onClick={() => {
          if (props.onClick) {
            props.onClick(item.id, item)
            return
          }
          mainservice.navTo([['view', 'spCustomerEdit'], ['id', item.id]])
        }}
      >
        <DisplayCustomerName
          item={item}
          options={props.options}
        />
      </button>
      <button
        title={`Standort von ...`}
        className={`w3-button textLeft width300`}
        onClick={() => {
          if (props.onClick && item.parentId > -1) {
            props.onClick(item.parentId, item)
            return
          }
          mainservice.navTo([['view', 'spCustomerEdit'], ['id', item.parentId]])
        }}
      >
        {item.parentName}
      </button>
      {
        customerPersonLexwareId === undefined && <LexwareButton item={item} kind='customer' />
      }
      <button
        className={`w3-button`}
        onClick={() => {
          navigator.clipboard.writeText(item.getPrintAddress(OptionArrayValue(props.options, 'customerPrefixAddress'))).then(function() {
            console.log('Text copied to clipboard');
          });
        }}
      ><Copy /></button>
      {
        props.onTrash &&
        <button
          className={`w3-button`}
          onClick={() => {
            if (props.onTrash) {
              props.onTrash(item.id)
            }
          }}
        ><Trash /></button>
      }
      {
        props.onRemove &&
        <button
          className={`w3-button`}
          onClick={props.onRemove}
        ><X /></button>
      }
    </div>
    {
      customerPersonLexwareId &&
      <div className='w3-padding'>
        <LexwareButton
          item={item}
          kind='customer'
          displayBillList={true}
          personId={customerPersonLexwareId}
        />
      </div>
    }
</div>
}

export function DisplayCustomerName(props: {
  item: ItemCustomerDTO,
  options?: [string, string][]
  onClick?: () => void,
  lazy?: boolean,
}) {
  const [item, setItem] = useState(props.item) // props.item
  const options = props.options
  const onClick = props.onClick || (() => {})
  const [status, setStatus] = useState(props.lazy ? Status.loading : Status.standard)
  useEffect(() => {
    if (props.lazy) {
      const newItem = new ItemCustomerDTO(item)
      newItem.getFromServer().then(() => {
        setItem(newItem)
        setStatus(Status.standard)
      })
    }
  })
  if (status === Status.standard && OptionArrayHasValue(options, 'customerShowAddress', 'yes')) {
    return <>
      <div onClick={onClick}>{item.name}</div>
      <div onClick={onClick} className='lightFont' dangerouslySetInnerHTML={{__html: 
        [
          item.getPropV1('data', 'street'),
          [item.getPropV1('data', 'postalcode'), item.getPropV1('data', 'town')].filter(i => i).join(' '),
          countryDisplay(item.getPropV1('data', 'country'))
        ].filter(i => i).join('<br />')
      }}>

      </div>
    </>
  }
  if (status === Status.standard && OptionArrayHasValue(options, 'customerShowCityAndAddress', 'yes')) {
    let cityAndAddress = item.cityAndAddress
    if (!cityAndAddress) {
      cityAndAddress = [item.getPropV1('data', 'town'), item.getPropV1('data', 'street')].filter(i => i).join(', ')
    }
    return <>
      <div onClick={onClick}>{item.name}</div>
      <div onClick={onClick} className='lightFont'>
        {cityAndAddress}
      </div>
    </>
  }
  return <>{item.name}</>
}
