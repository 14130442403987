import { GenericEditField, DisplayScope, EditFieldKind } from "../DTO/GenericDTO";


export const EventSpecificEditFields1: GenericEditField[] = [
  {
    title: 'Beschreibung',
    key1: 'data',
    key2: 'description',
    scope: DisplayScope.seminar,
    kind: EditFieldKind.textArea,
  },
  {
    title: 'Bemerkungen',
    key1: 'data',
    key2: 'notes',
    className: 'floatRight w3-border-yellow w3-padding w3-round w3-leftbar',
    emojiWhenFilled: 'Hm⚠️',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.textArea,
  },
  {
    title: 'Auftragsstatus',
    key1: 'tag',
    key2: 'contractState',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.tag,
    // kind: EditFieldKind.multitag,
    oneForEachAppointment: 'plusMain',
  },
  {
    title: 'Einzeln Buchbar',
    key1: 'data',
    key2: 'individualBookable',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.check,
    // kind: EditFieldKind.multitag,
    oneForEachAppointment: 'plusMain',
  },
  {
    title: 'Bearbeitungsstand',
    key1: 'tag',
    key2: 'workStateTag',
    scope: DisplayScope.template,
    kind: EditFieldKind.tag,
  },
  {
    title: 'Fertigstellungspriorität',
    key1: 'tag',
    key2: 'priorityA',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.tag,
  },
  {
    title: 'Fertigstellungsdatum',
    key1: 'data',
    key2: 'dueDate',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.date,
  },
  {
    title: 'Angebotsnummer',
    key1: 'data',
    key2: 'offerId',
    scope: DisplayScope.seminar,
    kind: EditFieldKind.string,
  },
  {
    title: 'Umsatz',
    key1: 'data',
    key2: 'estimatedTurnover',
    scope: DisplayScope.seminar,
    kind: EditFieldKind.number,
  },
  {
    title: 'Umsatz offen',
    key1: 'data',
    key2: 'turnoverMising',
    scope: DisplayScope.seminar,
    kind: EditFieldKind.number,
  },
  {
    title: 'Abrechnungsstatus',
    key1: 'tag',
    key2: 'billingStatus',
    scope: DisplayScope.seminar,
    kind: EditFieldKind.tag,
  },
  {
    title: 'Veranstaltungsnummer',
    key1: 'data',
    key2: 'uniqueEventId',
    scope: DisplayScope.seminar,
    kind: EditFieldKind.uniqueEventId,
  },
  {
    title: 'Sprache',
    key1: 'tag',
    key2: 'language',
    scope: DisplayScope.templateAndSeminarRead,
    kind: EditFieldKind.tag,
  },
  {
    title: 'Art der Veranstaltung',
    key1: 'tags',
    key2: 'eventKind',
    scope: DisplayScope.templateAndSeminarRead,
    kind: EditFieldKind.tag,
  },
  {
    title: 'Themen',
    key1: 'tags',
    key2: 'subjectTags',
    scope: DisplayScope.templateAndSeminarRead,
    kind: EditFieldKind.multitag,
  },
];
