import config from '../config.json'
import {parseStringFromServer} from '../services/TextConverter'

export default class PropDTO {
  apiPrefix = config.apiPrefix
  public id: number
  public key1: string
  public key2: string
  public value1: string
  public value2: string

  constructor(o: IProp) {
    this.id = o.id
    this.key1 = o.key1
    this.key2 = o.key2
    this.value1 = parseStringFromServer(o.value1)
    this.value2 = parseStringFromServer(o.value2)
  }

  get() {
    return {
      id: this.id,
      key1: this.key1,
      key2: this.key2,
      value1: this.value1,
      value2: this.value2
    }
  }
}

export interface IProp {
  id: number
  key1: string
  key2: string
  value1: string
  value2: string
}

export class PropsDTO {
  props: PropDTO[]
  constructor(o: IProp[]) {
    this.props = o.map(p => new PropDTO(p))
  }
  get(key1: string, key2: string) {
    return this.props.find(p => p.key1 === key1 && p.key2 === key2)
  }
  getValue1(key1: string, key2: string) {
    const p = this.get(key1, key2)
    if (p) {
      return p.value1
    }
  }
  remove(key1: string, key2: string) {
    this.props = this.props.filter(p => p.key1 === key1 && p.key2 === key2)
  }

}
