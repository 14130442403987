import { Setter } from "../../services/ComService";
import { countryDisplay } from "../../services/country";
import { CustomerEditFields } from "./CustomerEditFields";
import ItemDTO, { IItem } from "./ItemDTO";

export default class ItemCustomerDTO extends ItemDTO {
  customerNumber?: number
  eventId: number = -1
  personId: number = -1
  parentId: number = -1
  parentName: string = ''
  constructor(o: IItemCustomer) {
    super(o)
    this.customerNumber = o.customerNumber
    this.srcAdd = 'spt/customer/add'
    this.srcDel = 'spt/customer/delete'
    this.srcPatch = 'spt/customer/patch'
    this.srcGet = 'spt/customer/get'
    this.srcAddProp = 'spt/customer/prop/add'
    this.srcPatchProp = 'spt/customer/prop/patch' // Wird auf Server Seite überprüft, ob es sich um ein patch handelt
    this.srcDelProp = 'spt/customer/prop/del'
    this.eventId = o.eventId || -1
    this.personId = o.personId || -1
    this.editFields = CustomerEditFields
    this.propOptions = {
      srcAddProp: this.srcAddProp,
      srcPatchProp: this.srcPatchProp,
      srcDelProp: this.srcDelProp,
    }
    if (this.eventId > -1) {
      this.id = -2
      this.srcGet = 'spt/event/getCustomer'
      this.optionsGet = {
        id: this.eventId
      }
    } else if (this.personId > -1) {
      this.id = -2
      this.srcGet = 'spt/person/getCustomer'
      this.optionsGet = {
        id: this.personId
      }
    }
    this.parentId = o.parentId || -1
    this.parentName = o.parentName || ''
    
  }

  getCustomerNumber() {
    return this.customerNumber || 'NA'
  }

  afterInit(o: any) {
    this.customerNumber = o.customerNumber
    this.parentId = o.parentId || -1
    this.parentName = o.parentName || ''
    console.log('Customer done', this, this.parentId, o)
  }

  public async addPerson(personId: number) {
    await Setter('spt/person/addCustomer', {
      id: personId,
      customerId: this.id,
    })
  }

  public async removePerson(personId: number) {
    await Setter('spt/person/removeCustomer', {
      id: personId,
      customerId: this.id,
    })
  }

  public async addCustomerLocation(customerLocationId: number) {
    await Setter('spt/customer/addLocation', {
      id: this.id,
      locationId: customerLocationId,
    })
  }

  public async addAsCustomerLocation(parentId: number) {
    const result = await Setter('spt/customer/addLocation', {
      id: parentId,
      locationId: this.id,
    })
    this.init(result.item)
  }

  public async unlinkParent() {
    await Setter('spt/customer/unlinkParent', {
      id: this.id,
    })
    this.parentId = -1
    this.parentName = ''
  }

  public async unlinkLocation(id: number) {
    await Setter('spt/customer/unlinkParent', {
      id: id,
    })

  }

  public async addEvent(eventId: number) {
    await Setter('spt/event/addCustomer', {
      id: eventId,
      customerId: this.id,
    })
  }
}

export interface IItemCustomer extends IItem {
  customerNumber?: number,
  eventId?: number,
  personId?: number,
  parentId?: number,
  parentName?: string,
}

